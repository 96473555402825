<template>
	<v-row class="fill-height">
		<v-col>
			<v-sheet height="64">
				<v-toolbar flat>
					<v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> Today </v-btn>
					<v-btn fab text small color="grey darken-2" @click="prev">
						<v-icon small> mdi-chevron-left </v-icon>
					</v-btn>
					<v-btn fab text small color="grey darken-2" @click="next">
						<v-icon small> mdi-chevron-right </v-icon>
					</v-btn>
					<v-toolbar-title v-if="$refs.calendar">
						{{ $refs.calendar.title }}
					</v-toolbar-title>
					<v-spacer></v-spacer>
					<template>
						<v-layout class="justify-end">
							<div class="mx-1" style="max-width: 300px">
								<date-range-picker
									v-on:click:clear="
										search['date-range'] = [];
										searchOrders();
									"
									hide-details
									hide-top-margin
									clearable
									v-model="search['date-range']"
								></date-range-picker>
							</div>
							<div class="mx-1" style="max-width: 300px">
								<select-input
									v-on:click:clear="
										search['transaction-type'] = [];
										searchOrders();
									"
									clearable
									:items="transaction_items"
									hide-details
									custom-class="pt-0"
									placeholder="Transactions Type"
									multiple
									v-model="search['transaction-type']"
								></select-input>
							</div>
							<div class="mx-1" style="max-width: 300px">
								<select-input
									v-on:click:clear="
										search['item-type'] = [];
										searchOrders();
									"
									clearable
									:items="item_type_items"
									hide-details
									custom-class="pt-0"
									placeholder="Item Type"
									multiple
									v-model="search['item-type']"
								></select-input>
							</div>
							<div class="mx-1" style="max-width: 300px">
								<select-input
									v-on:click:clear="
										search['incharge-officer'] = [];
										searchOrders();
									"
									clearable
									:items="incharge_items"
									hide-details
									custom-class="pt-0"
									placeholder="Staff"
									multiple
									v-model="search['incharge-officer']"
								></select-input>
							</div>
							<div class="mx-1" style="max-width: 300px">
								<select-input
									v-on:click:clear="
										search['customer'] = [];
										searchOrders();
									"
									clearable
									:items="customer_items"
									hide-details
									custom-class="pt-0"
									placeholder="customer"
									multiple
									v-model="search['customer']"
								></select-input>
							</div>
							<div class="d-flex">
								<v-btn color="blue darken-4 white--text" depressed tile v-on:click="searchOrders()">
									<v-icon>mdi-magnify</v-icon>
								</v-btn>
							</div>
						</v-layout>
					</template>
					<v-menu bottom right>
						<template v-slot:activator="{ on, attrs }">
							<v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
								<span>{{ typeToLabel[type] }}</span>
								<v-icon right> mdi-menu-down </v-icon>
							</v-btn>
						</template>

						<v-list>
							<v-list-item @click="type = 'day'">
								<v-list-item-title>Day</v-list-item-title>
							</v-list-item>
							<v-list-item @click="type = 'week'">
								<v-list-item-title>Week</v-list-item-title>
							</v-list-item>
							<v-list-item @click="type = 'month'">
								<v-list-item-title>Month</v-list-item-title>
							</v-list-item>
							<v-list-item @click="type = '4day'">
								<v-list-item-title>4 days</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-toolbar>
			</v-sheet>
			<v-sheet height="1000">
				<v-calendar
					ref="calendar"
					v-model="focus"
					color="primary"
					:events="events"
					:event-color="getEventColor"
					:type="type"
					@click:event="showEvent"
					@click:more="viewDay"
					@click:date="viewDay"
					@change="updateRange"
				></v-calendar>

				<v-menu
					v-model="selectedOpen"
					:close-on-content-click="false"
					:activator="selectedElement"
					offset-x
				>
					<v-card color="grey lighten-4" flat class="card-calander">
						<v-toolbar :color="selectedEvent.color" dark>
							<v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
							<v-spacer></v-spacer>
						</v-toolbar>
						<v-card-text>
							<table width="100%" class="detail-table scattered-table">
								<tr>
									<th width="100px">Related To # :</th>
									<td>
										<ShowValue :object="selectedEvent" object-key="barcode" label="barcode"></ShowValue>
									</td>
									<template>
										<th width="100px">Type :</th>
										<td>
											<ShowValue :object="selectedEvent" object-key="type" label="type"></ShowValue>
										</td>
									</template>
								</tr>
								<tr>
									<th width="100px">Start Date :</th>
									<td>
										{{ selectedStart }}
									</td>
									<th width="100px">End Date :</th>
									<td>
										{{ selectedEnd }}
									</td>
								</tr>
								<tr>
									<template v-if="selectedEvent && selectedEvent.member_name">
										<th width="100px">Inchagre Officer :</th>
										<td>
											{{ selectedEvent.member_name }}
										</td>
									</template>
									<template v-else>
										<th width="100px">Customer :</th>
										<td>
											{{ selectedEvent.customer_name }}
										</td>
									</template>
								</tr>
							</table>
						</v-card-text>
						<v-card-actions>
							<v-btn text color="white" class="bg-primary" @click="selectedOpen = false"> Cancel </v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</v-sheet>
		</v-col>
	</v-row>
</template>
<style>
table.detail-table td {
	height: 40px;
	font-weight: 600;
}
.v-menu__content.theme--light.menuable__content__active {
	min-width: 420px !important;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import DateRangePicker from "@/view/components/DateRangePicker";
import SelectInput from "@/view/components/SelectInput";

import ObjectPath from "object-path";
import { isString, toSafeInteger, map } from "lodash";
import { mapGetters } from "vuex";

export default {
	data: () => ({
		focus: "",
		type: "month",
		typeToLabel: {
			month: "Month",
			week: "Week",
			day: "Day",
			"4day": "4 Days",
		},
		selectedEvent: {},
		selectedStart: null,
		selectedEnd: null,
		selectedElement: null,
		selectedOpen: false,
		calenderData: [],
		events: [],
		colors: ["blue darken-1", "green darken-1", "red darken-1"],
		names: ["Issued", "Receive", "Reservation"],
		search: {
			"date-range": [],
			"transaction-type": [],
			"item-type": [],
			"incharge-officer": [],
			customer_items: [],
		},
		transaction_items: [
			{ text: "Issue", value: "issue" },
			{ text: "Receive", value: "receive" },
			{ text: "Reservation", value: "reservation" },
		],
		item_type_items: [
			{ text: "Asset", value: "asset" },
			{ text: "Asset Stock", value: "asset-stock" },
			{ text: "Perishable", value: "perishable" },
		],
		incharge_items: [],
		customer_items: [],
	}),

	methods: {
		searchOrders() {
			this.$router
				.replace({
					name: this.$route.name,
					query: {
						...this.$route.query,
						...this.search,
					},
				})
				.then(() => {
					this.getCalender();
				})
				.catch(() => {});
		},

		viewDay({ date }) {
			this.focus = date;
			this.type = "day";
		},
		getEventColor(event) {
			return event.color;
		},
		setToday() {
			this.focus = "";
		},
		prev() {
			this.$refs.calendar.prev();
		},
		next() {
			this.$refs.calendar.next();
		},
		showEvent({ nativeEvent, event }) {
			const open = () => {
				this.selectedEvent = event;
				this.selectedStart = this.formatDate(event.start);
				this.selectedEnd = this.formatDate(event.end);

				this.selectedElement = nativeEvent.target;
				requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
			};

			if (this.selectedOpen) {
				this.selectedOpen = false;
				requestAnimationFrame(() => requestAnimationFrame(() => open()));
			} else {
				open();
			}

			nativeEvent.stopPropagation();
		},
		updateRange() {
			let eventDataArr = this.calenderData;

			this.events = map(eventDataArr, (row) => {
				return {
					name: row.name,
					start: new Date(row.start),
					end: new Date(row.end),
					timed: false,
					color: row.color,
					barcode: row.barcode,
					related_name: row.related_name,
					customer_name: row.customer_name,
					member_name: row.member_name,
					type: row.type,
				};
			});

			/* for (let i = 0; i < eventCount; i++) {
				const firstTimestamp = this.rnd(min.getTime(), max.getTime());
				const first = new Date(firstTimestamp - (firstTimestamp % 900000));
				const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
				const second = new Date(first.getTime() + secondTimestamp);
				events.push({
					name: this.names[this.rnd(0, this.names.length - 1)],
					start: first,
					end: second,
					color: this.colors[this.rnd(0, this.colors.length - 1)],
				});
			}

			this.events = events; */
		},
		rnd(a, b) {
			return Math.floor((b - a + 1) * Math.random()) + a;
		},
		getCalender() {
			this.pageLoading = true;

			const relational = new Object({});

			if (this.internal) {
				relational["internal"] = 1;
				relational["type"] = this.relation_type;
				relational["type-id"] = this.relation_type_id;
			}

			const filter = new Object({
				status: "all",
				...this.$route.query,
				...this.defaultFilter,
				...relational,
				tz: new Date().getTime(),
			});
			ApiService.setHeader();
			ApiService.query("calendar", filter).then(({ data }) => {
				this.calenderData = data;
				this.updateRange();
			});
		},
	},
	components: {
		ShowValue,
		DateRangePicker,
		SelectInput,
	},
	computed: {
		...mapGetters(["localDB"]),
	},
	mounted() {
		this.incharge_items = this.localDB("members", []);
		this.customer_items = this.localDB("customers", []);

		const date_range = ObjectPath.get(this.$route, "query.date-range", []);
		if (isString(date_range)) {
			this.search["date-range"] = [date_range];
		} else {
			this.search["date-range"] = date_range;
		}

		const transaction_type = ObjectPath.get(this.$route, "query.transaction-type", []);
		if (isString(transaction_type)) {
			this.search["transaction-type"] = [toSafeInteger(transaction_type)];
		} else {
			this.search["transaction-type"] = map(transaction_type, (row) => {
				return toSafeInteger(row);
			});
		}

		const item_type = ObjectPath.get(this.$route, "query.item-type", []);
		if (isString(item_type)) {
			this.search["item-type"] = [item_type];
		} else {
			this.search["item-type"] = item_type;
		}

		const incharge_officer = ObjectPath.get(this.$route, "query.incharge-officer", []);
		if (isString(incharge_officer)) {
			this.search["incharge-officer"] = [toSafeInteger(incharge_officer)];
		} else {
			this.search["incharge-officer"] = map(incharge_officer, (row) => {
				return toSafeInteger(row);
			});
		}
		const customer = ObjectPath.get(this.$route, "query.customer", []);
		if (isString(customer)) {
			this.search["customer"] = [toSafeInteger(customer)];
		} else {
			this.search["customer"] = map(customer, (row) => {
				return toSafeInteger(row);
			});
		}

		this.getCalender();
	},
};
</script>
